const IS_LOADING = 'is--loading';
const HAS_MODAL = 'has--modal';
const JS_ASYNC_LOAD = 'js-async-load';
import { onLoad } from 'javascript/app';
// import Router from '../../routes/router';

class AsyncLoad {
    constructor(element = false) {
        this.element = element ? element : document.body;

        window.onpopstate = event => {
            if (!event.target.location.hash) this.loadPage(window.location, this.onLoad);
        };
        if (element) {
            this.element.addEventListener('click', event => {
                if (event.target.tagName !== 'A') return;
                if (history === null) return;

                event.preventDefault();

                const data = this.element.dataset.asyncLoad;
                const href = event.target.pathname;
                const newUrl = Router.matchedRoute(href) ? Router.matchedRoute(href).template : href;
                this.loadPage(newUrl, this.onLoad, data);
                history.pushState(null, '', href);
            });
        }
    }

    popupState() {
        if (event.state) this.loadPage(window.location);
    }

    defaultOutAnimation() {
        document.documentElement.classList.add(IS_LOADING);
        document.documentElement.classList.remove(HAS_MODAL);
        document.documentElement.dataset.postData = '';
    }

    outAnimation() {
        const attribute = this.element.getAttribute(JS_ASYNC_LOAD);
        if (attribute) {
            document.body.classList.add(`t-animation-out--${attribute}`);

            const transition = document.querySelector(`.t-animation-out--${attribute}`);
            let transitionCount = 0;

            transition.addEventListener('transitionstart', () => (transitionCount += 1));

            transition.addEventListener('transitionend', event => {
                transitionCount -= 1;
                if (!transitionCount) this.defaultOutAnimation();
            });
        } else {
            this.defaultOutAnimation();
        }
    }

    inAnimation() {
        document.documentElement.classList.remove(IS_LOADING);
    }

    onLoad(data = false) {
        document.documentElement.dataset.postData = data;
        new onLoad();
    }

    loadPage(newUrl, onLoadFunction, onLoadData = false, holdPosition = false) {
        this.outAnimation();

        const httpRequest = new XMLHttpRequest();
        httpRequest.onreadystatechange = event => {
            if (httpRequest.readyState !== XMLHttpRequest.DONE) return;

            const newDocument = httpRequest.responseXML;
            if (newDocument === null) return;

            const newContent = httpRequest.responseXML.body;
            if (newContent === null) return;

            document.title = newDocument.title;
            document.body.parentNode.replaceChild(newContent, document.body);

            this.inAnimation();

            if (!holdPosition) window.scrollTo(0, 0);

            if (onLoadData) {
                const data = onLoadData.split('|');
                onLoadFunction(...data);
            } else {
                onLoadFunction();
            }
        };

        httpRequest.open('GET', newUrl);
        httpRequest.responseType = 'document';
        httpRequest.send();
    }
}
export default AsyncLoad;
