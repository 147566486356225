import Observer from '../utils/Observer';
import Subject from '../utils/Subject';
import FilterNodes from '../utils/FilterNodes';

// todo, make component
export default function Carousel() {
    // return false;
    const zoomElement = document.getElementById('carouselZoom');
    console.log('--> 1');

    if (zoomElement) {
        zoomElement.activeNumber = 0;
    } else {
        console.log('nothing tosee her ');
    }

    const init = () => {
        console.log('--> 2');

        console.log(document.getElementsByClassName('carousel'));

        console.log([...document.getElementsByClassName('carousel')]);
        [...document.getElementsByClassName('carousel')].map((el) => {
            console.log('--> 3');
            const observable = new Subject(0);
            attachIntersectionObserver(el, observable);
            attachControls(el, observable);
            assignPaginator(el, observable);
        });
    };

    init();
}

function attachControls(el, observable) {
    let element = el;
    let controls = [...FilterNodes(el.parentNode.children, 'I')];
    const maxItems = el.children.length;

    controls.map((control) => {
        if (control.classList.contains('next')) {
            console.log(control, 'next');
            control.addEventListener('click', (el) => {
                nextItem(el);
            });
        }

        if (control.classList.contains('previous')) {
            control.addEventListener('click', (el) => {
                previousItem(el);
            });
        }
    });

    const nextItem = (el) => {
        const w = parseInt(window.getComputedStyle(element).getPropertyValue('width'));

        if (observable.state < maxItems - 1) {
            observable.setState(observable.state + 1);
            element.scrollTo({ left: w * observable.state, behavior: 'smooth' });
        }
    };

    const previousItem = (el) => {
        const w = parseInt(window.getComputedStyle(element).getPropertyValue('width'));

        if (observable.state > 0) {
            observable.setState(observable.state - 1);
            element.scrollTo({ left: w * observable.state, behavior: 'smooth' });
        }
    };
}

// refactor, paginator ul / li should be generated
function assignPaginator(el, observable) {
    const carouselElement = el;
    let elements;

    const handleClick = (el) => {
        setState(elements.indexOf(el.target));
        const w = parseInt(window.getComputedStyle(carouselElement).getPropertyValue('width'));
        carouselElement.scrollTo({ left: w * elements.indexOf(el.target), behavior: 'smooth' });
    };

    const setState = (n) => {
        elements.map((el) => {
            el.classList.remove('active');
        });

        elements[n].classList.add('active');
    };

    if (el.nextElementSibling.getAttribute('type') === 'carousel') {
        elements = FilterNodes(FilterNodes(el.nextElementSibling.childNodes, 'UL')[0].childNodes, 'LI');

        elements.map((el) => {
            el.addEventListener('click', handleClick);
        });

        new Observer(observable).subscribe((n) => {
            setState(n);

            // smerig
            document.getElementById('carouselZoom').setAttribute('active', n);
        });
    }
}

function attachIntersectionObserver(el, observable) {
    let options = {
        root: el,
        rootMargin: '0px',
        threshold: 0.5,
    };

    let callback = (entries, observer) => {
        entries.map((entry) => {
            if (entry.intersectionRatio > 0.5) {
                observable.setState([...entry.target.parentNode.children].indexOf(entry.target));
                entry.target.classList.remove('hide');
            } else {
                entry.target.classList.add('hide');
            }
        });
    };

    let observer = new IntersectionObserver(callback, options);

    [...el.children].map((el) => {
        observer.observe(el);
    });
}
