import FilterNodes from './FilterNodes';

export default function ImagePopup() {
    const popupContainer = document.getElementById('imagepopupcontainer');
    const titleElement = document.getElementById('popupTitle');
    const serialElement = document.getElementById('popupSerial');
    const nextElement = document.getElementById('nextPopup');
    const previousElement = document.getElementById('previousPopup');
    const zoomElement = document.getElementById('carouselZoom');
    const closeiframepopup = document.getElementsByClassName('closeiframepopup')[0];
    const iframePopup = document.getElementsByClassName('popup')[0];
    const iframeSRC = document.getElementsByClassName('popup__iframe')[0];
    // return false;

    let activeImageId = 0;
    let imagesCount = 0;

    const initImageViewer = () => {
        // let accordions = [...document.getElementsByClassName('accordion')];
    };

    const initAccordion = () => {
        let accordions = [...document.getElementsByClassName('accordion')];

        accordions.map((accordion) => {
            let lis = FilterNodes(accordion.childNodes, 'LI');
            lis.map((li) => {
                let els = FilterNodes(FilterNodes(li.childNodes, 'UL')[0].childNodes, 'LI');

                els.map((el) => {
                    let anchors = FilterNodes(el.childNodes, 'A');
                    anchors.map((a) => {
                        if (a.classList.contains('btn')) {
                            a.addEventListener(
                                'click',
                                function (el) {
                                    window.event.preventDefault();
                                    const img = a.href;
                                    const path = el.path || (el.composedPath && el.composedPath());

                                    // crawl data from elements;
                                    // todo : this is not so dynamic.
                                    const elements = path[1].children;
                                    let title = elements[0].innerText;
                                    let serial = elements[1].innerText;

                                    expand({ img: [img], title, serial });
                                }.bind(popupContainer),
                                false
                            );
                        } else {
                            a.addEventListener('click', function (el) {
                                window.event.preventDefault();
                                const url = a.href;
                                iframeSRC.src = url;
                                iframePopup.classList.add('visible');
                            });
                        }
                    });
                });
            });
        });
    };

    const expand = (payload) => {
        const { img, title, serial } = payload;

        const imageContainer = document.getElementById('imageContainer');

        console.log('-->', img, title, serial);
        titleElement.innerText = title;
        serialElement.innerText = serial;

        // remove images, this is the fastest way. much faster then .innerHTML = ''
        // https://stackoverflow.com/questions/3955229/remove-all-child-elements-of-a-dom-node-in-javascript
        var cNode = imageContainer.cloneNode(false);
        imageContainer.parentNode.replaceChild(cNode, imageContainer);

        //add images(
        img.map((image) => {
            const el = document.createElement('IMG');
            el.src = image;
            cNode.appendChild(el);
        });

        imagesCount = img.length;

        if (img.length > 1) {
            nextElement.classList.add('btn--show');
            previousElement.classList.add('btn--show');
        }
        popupContainer.classList.add('show');
    };

    const attachCloseHandler = () => {
        document.getElementById('closePopup').addEventListener('click', () => {
            popupContainer.classList.remove('show');
        });

        if (closeiframepopup)
            closeiframepopup.addEventListener('click', () => {
                iframePopup.classList.remove('visible');
            });
    };

    const attachZoomHandler = () => {
        zoomElement.addEventListener('click', (el) => {
            const active = el.target.getAttribute('active');
            activeImageId = active;
            const path = el.path || (el.composedPath && el.composedPath());
            const images = [...FilterNodes([...path[1].childNodes], 'DIV')[0].children];

            imagesCount = images.length;

            // please refactor this
            titleElement.innerText =
                el.target.parentNode.parentNode.parentNode.parentNode.previousElementSibling.children[0].innerText;
            serialElement.innerText = el.target.parentNode.parentNode.children[1].children[1].innerText;

            var cNode = imageContainer.cloneNode(false);
            imageContainer.parentNode.replaceChild(cNode, imageContainer);

            images.map((image) => {
                const el = document.createElement('IMG');
                el.src = image.getAttribute('data-original');
                cNode.appendChild(el);
            });

            if (images.length > 1) {
                nextElement.classList.add('btn--show');
                previousElement.classList.add('btn--show');
            }

            popupContainer.classList.add('show');
            const w = parseInt(window.getComputedStyle(imageContainer).getPropertyValue('width'));
            imageContainer.scrollTo({ left: w * active });
        });
    };

    const attachControls = () => {
        nextElement.addEventListener('click', nextItem);
        previousElement.addEventListener('click', previousItem);
    };

    const nextItem = (el) => {
        const w = parseInt(window.getComputedStyle(imageContainer).getPropertyValue('width'));
        if (activeImageId < imagesCount - 1) {
            activeImageId++;
            imageContainer.scrollTo({ left: w * activeImageId, behavior: 'smooth' });
        }

        previousElement.classList.add('btn--show');

        if (activeImageId === imagesCount - 1) {
            nextElement.classList.remove('btn--show');
        } else {
            nextElement.classList.add('btn--show');
        }
    };

    const previousItem = (el) => {
        const w = parseInt(window.getComputedStyle(imageContainer).getPropertyValue('width'));
        if (activeImageId > 0) {
            activeImageId--;
            imageContainer.scrollTo({ left: w * activeImageId, behavior: 'smooth' });
        }

        nextElement.classList.add('btn--show');
        if (activeImageId === 0) {
            previousElement.classList.remove('btn--show');
        } else {
            previousElement.classList.add('btn--show');
        }
    };

    if (popupContainer) {
        initAccordion();
        initImageViewer();
        attachCloseHandler();
        if (zoomElement) attachZoomHandler();
        attachControls();
    }
}
