import CustomElement from '~utilities/custom-element';
import helpers from './../utils/DebounceHelper';
import FilterNodes from './../utils/FilterNodes';
import Parser from 'ua-parser-js';

// this logic only hides the menu when scrolling
// also add logic to the hamburger menu
class Menu extends CustomElement {
    constructor() {
        super();
        this.windowScrollYPos = window.scrollY;
        this.expanded = true; // true = down, // false up
        this.headerSection = document.getElementById('header');
        this.subMenu = document.getElementById('subMenu');
        this.navScroll = document.getElementById('navScroll');
        this.breadCrumb = document.getElementById('breadCrumb');
        this.nav = document.getElementById('nav');
        this.hamburger = document.getElementById('hamburger');
        this.languageToggle = document.getElementsByClassName('shorts__lang')[0];
        this.bgDark = this.getElementsByClassName('bgDark');

        this.parser = new Parser();
        let res = this.parser.getResult();
        this.browserinfo = res.browser;

        console.log('-->', this.browserinfo);

        if (
            this.browserinfo.name.toUpperCase() === 'IE' ||
            (this.browserinfo.name.toUpperCase() === 'EDGE' && parseInt(this.browserinfo.version) < 79)
        ) {
            console.log('ancient');
            try {
                document.getElementsByClassName('breadcrumb')[0].style.zIndex = '1000';
            } catch (e) {}
        } else {
            console.log('not ancient');
            document.addEventListener('scroll', new helpers().throttle(this.toggle, 50, this));
        }

        hamburger.addEventListener('click', this.toggleMenu.bind(this));

        if (this.languageToggle) {
            // this.languageToggle.addEventListener('mouseenter', () => this.toggleLanguage(true));
            // this.languageToggle.addEventListener('mouseleave', () => this.toggleLanguage(false));
        }

        if (this.subMenu) {
            this.applyHovers();
            this.addChevron();
            this.tempClickFix();
        }

        if (this.bgDark) {
            this.bgDarkHandler();
        }

        this.fixMenuHeights();
    }

    bgDarkHandler() {}

    toggleLanguage(state) {
        if (state) {
            this.languageToggle.classList.add('visible');
        } else {
            this.languageToggle.classList.remove('visible');
        }
    }

    // click triggers after mouse up
    // the focus state resets after mousedown, but before mouse up
    // so this is the temporary fix to make menuitems clickable
    tempClickFix() {
        [...this.subMenu.children].map((li) => {
            let ul = FilterNodes([...li.children], 'UL')[0];
            if (ul) {
                [...ul.children].map((l) => {
                    l.children[1].addEventListener('mousedown', (el) => {
                        el.target.classList.add('clicked');
                        const subMenuItems = el.target.parentNode.parentNode.parentNode;

                        [...subMenuItems.children].map((el) => {
                            el.classList.add('clicked');
                        });

                        setTimeout(() => {
                            window.location.replace(el.target.href);
                        }, 200);
                    });
                });
            }
        });
    }

    fixMenuHeights() {
        const breadCrumb = document.getElementsByClassName('breadcrumb')[0];
        const contentSection = document.getElementById('content');

        // check if selected main item has subitems
        const selected = [...this.nav.children[0].children].filter((subMenu) => {
            return subMenu.classList.contains('selected') ? subMenu : false;
        })[0];

        try {
            if (selected) {
                if (selected.children.subMenu) {
                    if (selected.children.subMenu.classList.contains('inactive')) {
                        contentSection.style.marginTop = '175px';
                        try {
                            breadCrumb.style.top = '145px';
                        } catch (e) {}
                    } else {
                        contentSection.style.marginTop = `${breadCrumb ? 225 : 175}px`;
                        if (window.innerWidth < 1000) {
                            contentSection.style.marginTop = '70px';
                        }
                    }
                } else {
                    if (breadCrumb) {
                        contentSection.style.marginTop = '175px';
                        breadCrumb.style.top = '145px';
                    } else {
                        contentSection.style.marginTop = '145px';
                    }
                }
            } else {
                if (breadCrumb) {
                    contentSection.style.marginTop = '175px';
                    breadCrumb.style.top = '145px';
                } else {
                    contentSection.style.marginTop = '145px';
                }
            }
        } catch (e) {
            console.log('home');
        }
    }

    applyHovers() {
        if ([...this.subMenu.childNodes]) {
            let submenuLi = [...this.subMenu.childNodes].filter((e) => e.nodeName == 'LI');

            [...submenuLi].map((el) => {
                if (el.childNodes[1]) {
                    el.childNodes[1].addEventListener('mouseenter', (element) => {
                        console.log('--->>>>', this.browserinfo.name.toUpperCase(), parseInt(this.browserinfo.version));

                        if (
                            this.browserinfo.name.toUpperCase() === 'IE' ||
                            (this.browserinfo.name.toUpperCase() === 'EDGE' && parseInt(this.browserinfo.version) < 79)
                        ) {
                            try {
                                document.getElementsByClassName('breadcrumb')[0].style.zIndex = '-1';
                            } catch (e) {}

                            let allUl = document.querySelectorAll('.subMenu ul');
                            [...allUl].forEach((ul) => {
                                ul.classList.remove('ie11fix');
                            });

                            let ul = element.target.parentNode.getElementsByTagName('UL')[0];

                            try {
                                ul.classList.add('ie11fix');
                            } catch (e) {}

                            ul.addEventListener('mouseleave', (item) => {
                                try {
                                    document.getElementsByClassName('breadcrumb')[0].style.zIndex = '1000';
                                } catch (e) {}

                                console.log(item.target);
                                item.target.classList.remove('ie11fix');
                                item.target.removeEventListener('mouseleave', () => {}, false);
                                window.focus();
                            });
                        }

                        element.target.focus();
                        this.applyWidths(element.target);
                    });
                }
            });
        }
    }

    applyWidths(el) {
        try {
            const pos = el.getBoundingClientRect();

            // hack to calculate the width of the green background
            // cannot expand background on flex column wrap

            const items = el.parentNode.children[2];
            let j = 0;
            if (items) {
                [...items.children].map((item) => {
                    const itemheight = parseInt(window.getComputedStyle(item).height);

                    j += itemheight;
                });
            }

            const subMenuWidth = document.getElementById('subMenu').offsetWidth;
            const marginLeft = (window.innerWidth - subMenuWidth) * 0.5;

            const numRowsWidth = Math.ceil(j / 318) * 280;
            const maxWidth = subMenuWidth - (pos.left - marginLeft);

            const width = numRowsWidth < maxWidth ? numRowsWidth : maxWidth;

            // console.log(numRowsWidth, width, maxWidth);

            // console.log(window.innerWidth);
            let ul = FilterNodes([...el.parentNode.children], 'UL')[0];
            // console.log(ul.scrollWidth);
            if (ul) ul.style.width = `${width}px`;
            // ul.style.maxwidth = '1110px';
            // ul.style.width = '1110px';
        } catch (e) {
            console.log(e);
        }
    }

    addChevron() {
        if ([...this.subMenu.childNodes]) {
            let submenuLi = [...this.subMenu.childNodes].filter((e) => e.nodeName == 'LI');

            submenuLi.map((el) => {
                if ([...el.childNodes]) {
                    let numOfLi = 0;

                    try {
                        let ul = [...el.childNodes].filter((e) => e.nodeName == 'UL')[0];
                        let li = [...ul.childNodes].filter((e) => e.nodeName == 'LI');

                        if (li) {
                            li.map((elLi) => {
                                numOfLi++;
                                let a = [...elLi.childNodes].filter((e) => e.nodeName == 'A')[0];
                                const newEl = document.createElement('i');
                                newEl.setAttribute('class', 'icon-chevron-right rotate');
                                a.before(newEl);
                            });
                        }
                    } catch (e) {}

                    let a = [...el.childNodes].filter((e) => e.nodeName == 'A')[0];
                    if (numOfLi > 0) {
                        const newEl = document.createElement('i');
                        newEl.setAttribute('class', 'icon-chevron-right');
                        a.appendChild(newEl);
                    } else {
                        try {
                            console.log(a.nextElementSibling.remove());
                        } catch (e) {
                            console.log(a);
                        }
                    }
                }
            });
        }
    }

    toggleMenu() {
        this.nav.classList.toggle('show');
        this.hamburger.classList.toggle('active');
    }

    toggle() {
        if (window.scrollY < 300) return;

        const prevExpanded = this.expanded;
        const expanded = this.windowScrollYPos > window.scrollY;
        let lock = window.scrollY - this.windowScrollYPos;

        if (lock !== 0) this.windowScrollYPos = window.scrollY;

        if (prevExpanded !== expanded && lock != 0) {
            this.expanded = expanded;

            try {
                if (this.expanded) {
                    this.headerSection.classList.remove('collapsed');
                    this.subMenu.classList.remove('collapsed');
                    this.navScroll.classList.remove('show');
                    this.breadCrumb.classList.remove('hide');
                } else {
                    this.headerSection.classList.add('collapsed');
                    this.subMenu.classList.add('collapsed');
                    this.navScroll.classList.add('show');
                    this.breadCrumb.classList.add('hide');
                }
            } catch (e) {}
        }
    }
}

export default Menu;
