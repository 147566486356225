const FORM_ITEM_ERROR_CLASS = 'form__item-error';
const FORM_ITEM_ERROR_INNER_CLASS = 'form__item-error-inner';
const JS_ERROR = 'js-error';
const ACTIVE_ERROR_CLASS = 'has--error';

class Error {
    constructor() {
        this.data = [];
    }

    show(element, message, name, sort) {
        this.remove(element, name, sort);
        
        const errorInner = document.createElement('span');
        errorInner.classList.add(FORM_ITEM_ERROR_INNER_CLASS);
        errorInner.innerText = message;
        
        const error = document.createElement('div');
        error.classList.add(FORM_ITEM_ERROR_CLASS);
        error.setAttribute(JS_ERROR, sort);

        element.appendChild(error);
        error.appendChild(errorInner);
        element.classList.add(ACTIVE_ERROR_CLASS);
    }

    remove(element, name, sort) {
        const error = element.querySelector(`[${JS_ERROR}=${sort}]`);
        if(error) error.parentElement.removeChild(error);

        if(!Object.keys(this.data[name]).length){
            element.classList.remove(ACTIVE_ERROR_CLASS);
        }
    }

    errorData(hasError, sort, field, element, message) {
        const name = field.length ? field[0].name : field.name;

        if(hasError && this.data[name] === undefined) this.data[name] = [];

        if(hasError && this.data[name] !== undefined) {
            this.data[name][sort] = {};
            this.data[name][sort].message = message;
            this.data[name][sort].label = element.querySelector('[js-label]').innerText;
            this.show(element, message, name, sort);

        } else if(this.data[name] !== undefined && this.data[name][sort] !== undefined){
            delete this.data[name][sort];
            this.remove(element, name, sort);
        }
    }

}

export default Error;