const IS_LOADING = 'is--loading';
const IS_ACTIVE = 'is--active';
const DESC = 'desc';
const ASC = 'asc';

class Filter {
    constructor(element) {
        this.element = element;
        this.filterEntriesData;
        this.filterContainer = this.element.querySelector('[js-filter-container]');
        this.filterEntries = this.element.querySelector('[js-filter-entries]');
        this.filterItems = this.element.querySelector('[js-filter-items]');
        this.filterSearch = this.element.querySelector('[js-filter-search]');
        this.filterSorts = [...this.element.querySelectorAll('[js-filter-sort]')];

        this.filterEntries.classList.add(IS_LOADING);
        
        fetch(this.element.dataset.filterUrl)
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            this.filterEntriesData = data;
            this.createItems(this.element);
            this.filterEntries.classList.remove(IS_LOADING);
            this.fetchedData(this.element);
        });
    }

    createItems() {
        this.filterEntries.innerHTML = '';
        Object.keys(this.filterEntriesData).forEach((key) => {
            this.createItem(this.filterEntriesData[key], key);
        });
    }

    createItem(entry, key) {
        this.filterEntries.innerHTML += `
            <div class="filter__entries-item ${entry.active ? IS_ACTIVE : ''}" 
                js-filter-entry="${key}"
                data-filter-categories='${entry.categories}'>
                ${entry.title}
            </div>
        `;
    }

    filterClick(element) {
        element.classList.toggle(IS_ACTIVE);
        const toggle = element.classList.contains(IS_ACTIVE);
        
        this.categoryArray = this.changeArray(this.categoryArray, toggle, element.getAttribute('data-filter-category'));
        this.removeOldFilters('search');
        this.filter(this.categoryArray, 'categories');
    }

    sortClick(sort) {
        sort.classList.toggle(IS_ACTIVE);
        const attribute = sort.getAttribute('js-filter-sort');
		
        const type = sort.classList.contains(IS_ACTIVE) ? DESC : ASC;

        this.filterSorts.forEach(sibling => {
            if(sort !== sibling) sibling.classList.remove(IS_ACTIVE);
        });
        sort.classList.remove(`is--${DESC}`, `is--${ASC}`);
        sort.classList.add(`is--${type}`);
		
		this.filterEntriesData = this.sortData(this.filterEntriesData, attribute, type);
		this.createItems();
    }

    sortData(data, key, type) {
        const compare = function(k, kk) {
            if(typeof data[k][key] === 'string') {
                if(type === 'asc') {
                    return data[kk][key].localeCompare(data[k][key]);
                } else {
                    return data[k][key].localeCompare(data[kk][key]);
                }
            } else {
                if(type === 'asc') {
                    return data[kk][key] - data[k][key];
                } else {
                    return data[k][key] - data[kk][key]
                }
            }
        };
    
        const ordered = Object.keys(data).sort(compare).reduce((prev, curr) => {
            prev[curr] = data[curr]
            return prev
        }, {});
        return ordered;
    }

    fetchedData(element) {
        this.filterItems = [...element.querySelectorAll('[js-filter-item]')];
        this.categoryArray = [];
        this.showCounters();
        
        this.bindEvents();
    }

    bindEvents() {
        this.filterItems.forEach(entry => {
            entry.addEventListener('click', () => this.filterClick(entry));
        });

        this.filterSorts.forEach(sort => {
            sort.addEventListener('click', () => this.sortClick(sort));
        });

        if(this.filterSearch) this.filterSearch.addEventListener('keyup', (event) => {
		
            let searchWords = this.filterSearch.value.toLowerCase().split(' ');
            searchWords = searchWords.filter(Boolean);

            this.removeOldFilters('categories');
            this.filter(searchWords, 'title');
        });
    }

    showCounters() {
        const entries = [...this.element.querySelectorAll('[js-filter-entry].is--active')];
    
        const counter = this.element.querySelector('[js-filter-count]');
        if(counter) counter.innerText = entries.length;

        const items = [...this.element.querySelectorAll('[js-filter-item]')]
        
        items.forEach(item => {
            const specificItem = item.getAttribute('data-filter-category');
            let count = 0;
            
            entries.forEach(entry => {
                const filters = entry.getAttribute('data-filter-categories');
                if(filters && filters.includes(specificItem.toLowerCase())) count++
            });

            const countItem = item.querySelector('[js-item-count]');
            if(countItem) countItem.innerText = `(${count})`;
        });
    }

    removeOldFilters(type) {
        if(type === 'categories') {
            const categories = [...this.element.querySelectorAll('[data-filter-category]')];
            categories.forEach(category => {
                category.classList.remove(IS_ACTIVE);
            });
        } else {
            if(this.filterSearch) this.filterSearch.value = '';
        }
    }

    changeArray(array, add, item) {
        item = item.toLowerCase();

        if(add) {
            array.push(item);
        } else {
            array = array.filter(e => e !== item);
        }
        return array;
    }


    containsAll(arr, arr2) {
        if(typeof arr2 === 'string') {
            arr2 = arr2.toLowerCase();
            return arr.every(function(item) {
                return !!~arr2.indexOf(item);
            });
        } else {
            return arr.every(function(i) {
                return arr2.includes(i);
            });	
        }
    };

    filter(items, object) {

        Object.keys(this.filterEntriesData).forEach((entry) => {
            const show = this.containsAll(items, this.filterEntriesData[entry][object]);
            const toggle = show ? 'add' : 'remove';
            
            if(items.length) {
                this.showEntry(entry, toggle, show);	
            } else {
                this.showEntry(entry, 'add', true);
            }
        });

        this.showCounters();
    }

    showEntry(entry, toggle, show) {
        const item = document.querySelector(`[js-filter-entry="${entry}"]`);
        item.classList[toggle](IS_ACTIVE);
        this.filterEntriesData[entry]['active'] = show;
    }
}

export default Filter;
