class GetServiceWorker {

    constructor() {
        if(!('serviceWorker' in navigator)) {
            console.log('SW not supported');
            return;
        }
        navigator.serviceWorker.register(
            '/service-worker.js', {
                scope: '/'
            }
        )
        .then((registration) => {
            console.log('SW registered. Scope is:', registration.scope);
        });
    }
}
export default new GetServiceWorker();