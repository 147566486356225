const arrayScripts = [];

class InitializeScript {

    async(attribute, script, parent = document) {
        const elements = [...parent.querySelectorAll(`[${attribute}]`)];
        if(!elements.length) return;
        
        elements.forEach(element => {
	        const object = {};
	        object.element = element;
	        object.script = script;

            if(!arrayScripts.includes(object)) {
			    arrayScripts.push(object);
				return new script(element, attribute);
			} else {
			    return;
			}
        });
    }
}
export default new InitializeScript();