class CustomSelect {
    constructor(element) {
        this.element = element;
	    this.select = this.element.querySelector('[js-custom-select-select]');
		  
		this.createSelected();
		this.createItems();
		  
		this.selected.addEventListener("click", (event) => {
			event.stopPropagation();
			this.selected.nextSibling.classList.toggle("is--hidden");
			this.selected.classList.toggle("is--active");
		});
		
		document.addEventListener("click", () => this.closeAllSelect());
    }
    
    createSelected() {
	    this.selected = document.createElement("DIV");
		this.selected.classList.add("custom-select__selected");
		this.selected.setAttribute('js-select-selected', '');
		this.selected.innerHTML = this.select.options[this.select.selectedIndex].innerHTML;
		this.element.appendChild(this.selected);
    }
    
    createItems() {
	    this.items = document.createElement("DIV");
		this.items.classList.add("custom-select__items");
		this.items.classList.add("is--hidden");
		this.items.classList.add("c-dropdown");
		this.items.setAttribute('js-select-items', '');
		
		for (let i = 1; i < this.select.length; i++) {
    		
    		if(!this.select.options[i].disabled){
    		
    			const item = document.createElement("DIV");
    			item.classList.add('dropdown__link');
    			item.innerHTML = this.select.options[i].innerHTML;
    			item.addEventListener("click", () => this.clickSelected(item));
    			this.items.appendChild(item);
			
			}
			
		}
		
		this.element.appendChild(this.items);
    }
    
    clickSelected(item) {
	    for (let i = 0; i < this.select.length; i++) {
	        if (this.select.options[i].innerHTML == item.innerHTML) {
		        this.select.selectedIndex = i;
		        this.selected.innerHTML = item.innerHTML;
		        const selectedItem = item.parentNode.querySelector(".is--selected");
		        if(selectedItem) selectedItem.classList.remove('is--selected');
		        
		        item.classList.add("is--selected");
		        break;
	        }
	    }
	    this.select.click();
    }
    
    closeAllSelect() {
		this.selected.classList.remove("is--active");
		this.items.classList.add("is--hidden");
	}
}

export default CustomSelect;