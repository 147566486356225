import Field from './field';

const JS_LABEL = '[js-label]';
// Auto add * after label
class Required extends Field{
    constructor(form, error, element) {
        super();
        this.form = form;
        this.element = element;
        this.error = error;

        this.fields = this.getFields(this.element);

        this.checkRequired();
    }

    checkRequired() {
        const labelText = this.element.querySelector(JS_LABEL).innerText;
        const message = `Het '${labelText}' veld is verplicht.`;

        let hasError = !this.hasValue(this.fields) ? true : false;
        this.error.errorData(hasError, `required`, this.fields, this.element, message);   
    }
}

export default Required;
