import Observer from '~utilities/observer';

class Image {
    constructor(element) {
        new Observer(element, this);
    }

    observerTrigger(target) {
        target.src = target.dataset.src;
    }

}

export default Image;