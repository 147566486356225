import colors from 'scss-settings/_settings.colors.scss';
import fonts from 'scss-settings/_settings.fonts.scss';
import icons from 'scss-settings/_settings.icons.scss';
import components from '~components/templates.js';
import utilities from '~utilities/templates.js';

const JS_STYLEGUIDE = 'js-styleguide';
const JS_STYLEGUIDE_COLORS = `[${JS_STYLEGUIDE}-colors]`;
const JS_STYLEGUIDE_FONTS = `[${JS_STYLEGUIDE}-fonts]`;
const JS_STYLEGUIDE_NAV = `[${JS_STYLEGUIDE}-nav]`;
const JS_STYLEGUIDE_HEADINGS = `[${JS_STYLEGUIDE}-headings]`;
const JS_STYLEGUIDE_COMPONENTS = `[${JS_STYLEGUIDE}-components]`;
const JS_STYLEGUIDE_UTILITIES = `[${JS_STYLEGUIDE}-utilities]`;
const JS_STYLEGUIDE_ICONS = `[${JS_STYLEGUIDE}-icons]`;
const JS_COMPONENTS_COPY = `js-component-copy`;
const JS_COMPONENTS_FULL = `js-component-full`;
const FONT_CLASS = 's-guide-font';
const HEADING_CLASS = 's-guide-heading';
const COMPONENT_CLASS = 's-guide-component';
const UTILITY_CLASS = 's-guide-utility';
const COPY_CLASS = 's-guide-copy';
const JS_SECTION = '[js-section]';
const JS_SUBSECTION = 'js-subsection';

class Styleguide {
    constructor(element) {
        this.element = element;
        this.addColors();
        this.addIcons();
        this.addFonts();
        this.addHeadings();
        this.addComponents(components);
        this.addUtilities();
        this.addNav();
    }

    copyToClipboard(text) {
        var selected = false;
        var element = document.createElement('textarea');
        element.value = text;
        element.setAttribute('readonly', '');
        element.style.position = 'absolute';
        element.style.left = '-9999px';
        document.body.appendChild(element);
        if (document.getSelection().rangeCount > 0) {
            selected = document.getSelection().getRangeAt(0)
        }
        element.select();
        document.execCommand('copy');
        document.body.removeChild(element);
        if (selected) {
            document.getSelection().removeAllRanges();
            document.getSelection().addRange(selected);
        }

        this.showCopyAnimation();
    };

    showCopyAnimation() {
        const element = document.createElement('div');
        element.classList.add([COPY_CLASS]);
        element.innerHTML = `<span class='guide-copy__text'>Copied element</span>`;
        document.body.appendChild(element);

        element.addEventListener("animationend", () => {
            element.remove();
        });
    }

    addNav() {
        const nav = this.element.querySelector(JS_STYLEGUIDE_NAV);
        const sections = [...this.element.querySelectorAll(JS_SECTION)];

        const ul = document.createElement('ul');
        ul.classList.add('guide-nav__items');
        nav.appendChild(ul);

        sections.forEach(section => {
            const element = this.createNavItem('h1', section, ul, 'guide-nav__item');

            const children = [...section.querySelectorAll(`[${JS_SUBSECTION}]`)];

            const childUl = document.createElement('ul');
            childUl.classList.add('guide-nav__childs');
            element.appendChild(childUl);

            children.forEach(child => {
                this.createNavItem('h2', child, childUl, 'guide-nav__child');
            });
        });
    }

    toLowerCase(string){
        return string.replace(/\s+/g, '-').toLowerCase()
    }

    camelToSlug(string) {
        return string.replace(/([A-Z])/g, "-$1").toLowerCase()
    }

    createNavItem(heading, item, parent, className) {
        const title = item.querySelector(heading).innerHTML;
        const id = this.toLowerCase(title);
        item.id = id;
        const element = document.createElement('li');
        element.classList.add(className);
        element.innerHTML = `<a href="#${id}" class="${className}-link">${title}</a>`;
        parent.appendChild(element);
        return element;
    }

    addColors() {
        const colorsElement = this.element.querySelector(JS_STYLEGUIDE_COLORS);
        const colorsContainer = document.createElement('div');
        colorsContainer.classList.add(['row']);
        Object.keys(colors).forEach((color) => {
            const colorVar = `$color-${this.camelToSlug(color)}`;
            const example = `<div class="guide-block__example" style="background-color: ${colors[color]};"></div>`;
            const element = this.addBlock(colorVar, colors[color], colorVar, example);
            colorsElement.appendChild(colorsContainer);
            colorsContainer.appendChild(element);
        });
    }

    addBlock(title, subtitle, clipboard, example) {
        const element = document.createElement('div');
        element.classList.add('col-md-3', 's-guide-block');
        let content = `
            ${example}
            <div class="guide-block__content">
                <span class="guide-block__title">${title}</span>
            `;
        if(subtitle){
            content += `<span class="guide-block__subtitle">${subtitle}</span>`;
        }
        content += `</div>`;
        element.innerHTML = content;
        element.addEventListener('click', () => {
            this.copyToClipboard(clipboard);
        });

        return element;
    }

    addFonts() {
        const fontsElement = this.element.querySelector(JS_STYLEGUIDE_FONTS);
        const parentElement = document.createElement('div');
        parentElement.classList.add('row');
        fontsElement.appendChild(parentElement);
        Object.keys(fonts).forEach((font) => {
            const element = document.createElement('div');
            element.classList.add([FONT_CLASS], ['col-md-4']);
            element.style.backgroundColor = fonts[font];
            element.innerHTML = `
                <div class='guide-font__heading'>$font-${this.camelToSlug(font)}</div>
                <div class='guide-font__examples' style='font-family:${fonts[font]}'>
                    <div class='guide-font__example guide-font__example--bold'>Aa</div>
                    <div class='guide-font__example guide-font__example--italic'>Aa</div>
                    <div class='guide-font__example guide-font__example--normal'>Aa</div>
                </div>
                <span class='guide-font__family' style='font-family:${fonts[font]}'>${fonts[font]}</span>
            `;
            parentElement.appendChild(element);
        });
    }

    addIcons() {
        const iconsElement = this.element.querySelector(JS_STYLEGUIDE_ICONS);
        const iconsContainer = document.createElement('div');
        iconsContainer.classList.add(['row']);
        Object.keys(icons).forEach((icon) => {
            const iconVar = `icon-${icons[icon]}`;
            const example = `<div class="guide-block__example"><i class='guide-block__inner ${iconVar}'></i></div>`;
            const element = this.addBlock(iconVar, '', `<i class='${iconVar}'></i>`,example);
            iconsElement.appendChild(iconsContainer);
            iconsContainer.appendChild(element);
        });
    }

    addHeadings() {
        const headingsElement = this.element.querySelector(JS_STYLEGUIDE_HEADINGS);
        for(let i = 1; i<=6; i++) {
            const element = document.createElement(`div`);
            element.classList.add('row');
            headingsElement.appendChild(element);

            element.innerHTML = `
                <h${i} class="col-4" js-guide-heading-example>This is an h${i}</${i}>
            `;
            const elementExample = element.querySelector(`[js-guide-heading-example]`);
            const elementStyles = window.getComputedStyle(elementExample);
            element.classList.add(HEADING_CLASS);
            element.innerHTML += `
                <span class="col-2">&#60;h${i}&#62; .u-h${i}</span>
                <span class="col-2">${elementStyles.getPropertyValue('line-height')}</span> 
                <span class="col-2">${elementStyles.getPropertyValue('font-size')}</span> 
                <span class="col-2">${elementStyles.getPropertyValue('font-weight')}</span>
            `;
        }
    }

    capitalize(string){
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    
    addComponent(name, path, template) {
        const element = document.createElement(`div`);
        element.id = this.toLowerCase(name);
        element.classList.add([COMPONENT_CLASS],[`col-md-12`]);
        element.setAttribute(JS_SUBSECTION, '');
        element.innerHTML = `
            <h2>${this.capitalize(name)}</h2><br>
            ${template}
            <div class="guide-component__buttons">
                <span class="guide-component__button guide-component__button--tertiary" ${JS_COMPONENTS_COPY}>Copy HTML</span>
                <a href="https://bitbucket.org/0to9/gootsteen/src/develop/src/components/${path}" target="_blank" class="guide-component__button guide-component__button--secondary">Documentation</a>
                <span class="guide-component__button" ${JS_COMPONENTS_FULL}>[&nbsp;&nbsp;]</span>
            </div>
        `;

        const copyElement = element.querySelector(`[${JS_COMPONENTS_COPY}]`);
        copyElement.addEventListener('click', () => {
            this.copyToClipboard(template);
        });

        const fullElement = element.querySelector(`[${JS_COMPONENTS_FULL}]`);
        fullElement.addEventListener('click', () => {
            element.classList.toggle('guide-component--full');
        });

        return element;
    }

    addComponents(components, parent = false) {
        const componentsElement = this.element.querySelector(JS_STYLEGUIDE_COMPONENTS);

        Object.keys(components).forEach((component) => {
            if(typeof components[component] === 'object') {
                this.addComponents(components[component], component);
            }else {
                let path = name = this.camelToSlug(component);
                if(parent) {
                    path = this.camelToSlug(parent);
                    name = `${parent}/${this.camelToSlug(component)}`
                }
                componentsElement.appendChild(this.addComponent(name, path, components[component]));
            }
        })
    }

    addUtilities() {
        const utilitiesElement = this.element.querySelector(JS_STYLEGUIDE_UTILITIES);

        utilities.forEach((utility) => {
            const element = document.createElement(`div`);
            element.id = this.toLowerCase(utility);
            utilitiesElement.appendChild(element);
            element.classList.add(`col-lg-3`, `col-md-4`, `col-xl-2`);
            element.setAttribute(JS_SUBSECTION, '');
            element.innerHTML = `
                <div class="${UTILITY_CLASS}">
                    <h2>${this.capitalize(utility)}</h2><br>
                    <div class="guide-component__buttons guide-component__buttons--relative">
                        <a href="https://bitbucket.org/0to9/gootsteen/src/develop/src/utilities/${utility}" target="_blank" class="guide-component__button">Documentation</a>
                    </div>
                </div>    
            `;
        })
    }
}
export default Styleguide;