import ToggleClass from '~utilities/toggle-class';

const FILE_INPUT = '[js-file-input]';
const FILE_RETURN = '[js-file-return]';
const FILE_REMOVE = '[js-file-remove]';
const FILE_PREVIEW = '[js-file-preview]';
const IS_VISIBLE = 'is--visible';

class File {
    constructor(element) {
        this.element = element;
        this.fileInput = this.element.querySelector(FILE_INPUT);
        this.fileReturn = this.element.querySelector(FILE_RETURN);
        this.fileRemoveBtn = this.element.querySelector(FILE_REMOVE);
        this.filePreview = this.element.querySelector(FILE_PREVIEW);

        this.bindEvents();
    }

    bindEvents() {
        this.fileInput.addEventListener('change', (event) => {
            this.changeReturn(); 
            if(this.filePreview) this.showPreview(event)
        });
        if(this.fileRemoveBtn) this.fileRemoveBtn.addEventListener('click', () => this.removeFile());
    }

    showPreview(event) {
        const type = event.target.files[0].type;

        if (type.includes('image')) {
            this.filePreview.src = URL.createObjectURL(event.target.files[0]);
            new ToggleClass(type.includes('image'), this.filePreview, IS_VISIBLE);
        }
    }

    removeFile() {
        this.fileInput.value = this.fileReturn.innerText = '';
        if(this.fileRemoveBtn) new ToggleClass(false, this.fileRemoveBtn, IS_VISIBLE);
        if(this.filePreview) new ToggleClass(false, this.filePreview, IS_VISIBLE);
        URL.revokeObjectURL(this.filePreview.src);
    }

    changeReturn() {
        const value = this.fileInput.value;
        
        if(this.fileReturn) this.fileReturn.innerText = this.getFileName(value);  
        if(this.fielRemoveBtn) new ToggleClass(value, this.fileRemoveBtn, IS_VISIBLE);
    }

    getFileName(value) {
        const startIndex = (value.indexOf('\\') >= 0 ? value.lastIndexOf('\\') : value.lastIndexOf('/'));
        let fileName = value.substring(startIndex);
        if (fileName.indexOf('\\') === 0 || fileName.indexOf('/') === 0) {
            fileName = fileName.substring(1);
        }
        return fileName;
    }
}

export default File;
