const ELEMENTS = 'a, area, input:not([disabled]), [tabindex="0"], select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, video, audio';

class TabIndexChildren {
    constructor(parent, indexValue, element = false) {
        if(!element) parent = document.querySelector(parent);

        const children = [...parent.querySelectorAll(ELEMENTS)];
        children.forEach((child) => {
            child.tabIndex = indexValue;
        });
    }
}

export default TabIndexChildren;