import TabIndexChildren from '~utilities/tab-index-children/index';

const JS_TAB_LIST = '[js-tab-list]';
const ACTIVE_CLASS = 'is--active';

class Tab {
	constructor(element) {
		this.element = element;
		this.tabFocus = 0;
		this.tabList = element.querySelector(JS_TAB_LIST);
		this.tabs = [...this.tabList.querySelectorAll('[role="tab"]')];
		this.setAttribute();
		this.bindEvents();
	}

	bindEvents() {
		this.tabs.forEach(tab =>
			tab.addEventListener('click', event => this.changeTabs(tab, event))
		);
		this.tabList.addEventListener('keydown', event => this.setTabFocus(event));
	}

	setTabFocus(event) {
		const key = event.keyCode;
		if (key === 39 || key === 37 || key === 38 || key === 40) {
			this.tabs[this.tabFocus].tabIndex = -1;

			if ((key === 39) | (key === 40)) {
				this.tabFocus++;
				if (this.tabFocus >= this.tabs.length) this.tabFocus = 0;
			} else {
				this.tabFocus--;
				if (this.tabFocus < 0) this.tabFocus = this.tabs.length - 1;
			}

			this.tabs[this.tabFocus].tabIndex = 0;
			this.tabs[this.tabFocus].focus();
		}
	}

	setAttribute() {
		this.tabs.forEach((tab, index) => {
			const id = tab.getAttribute('js-tab-header');
			if (id) {
				const tabPanel = this.element.querySelector(`[js-content='${id}']`);
				tab.id = id;
				tabPanel.setAttribute('role', 'tabpanel');
				tabPanel.setAttribute('aria-labelledby', `${id}`);
				tabPanel.id = `tab-panel-${id}`;
				tab.setAttribute('aria-controls', `tab-panel-${id}`);
				tab.classList.add('tab__tab');
				const current = tab.getAttribute('aria-selected') === 'true';

				this.setPanel(tab, tabPanel, current);
			}
		});
	}

	setPanel(tab, tabPanel, current) {
		const tabIndex = current ? 0 : -1;
		const toggle = current ? 'add' : 'remove';

		tab.tabIndex = tabIndex;
		tab.classList[toggle](ACTIVE_CLASS);
		if (tabPanel) {
			tabPanel.classList[toggle](ACTIVE_CLASS);
			new TabIndexChildren(tabPanel, tabIndex, true);
		}
	}

	changeTabs(target, event) {
		if (!target) event.preventDefault();

		this.tabs.forEach(tab => {
			tab.classList.remove(ACTIVE_CLASS);
			const tabPanel = this.element.querySelector(
				`[js-content='${tab.getAttribute('js-tab-header')}']`
			);
			let current = tab === target;

			const toggle = current ? true : false;
			const height = current ? '100%' : '0';
			tab.setAttribute('aria-selected', toggle);

			this.setPanel(tab, tabPanel, current);
		});
	}
}

export default Tab;
