import Field from './field';

const JS_LABEL = '[js-label]';
const JS_VALIDATION_TYPE = 'js-validation-type';

class ValidationType extends Field {
    constructor(form, error, element) {
        super();
        this.form = form;
        this.element = element;
        this.error = error;

        this.field = this.getFields(this.element)[0];
        this.checkValidationType();
    }

    checkValidationType() {
        const validationType = this.element.getAttribute(JS_VALIDATION_TYPE);
        const labelText = this.element.querySelector(JS_LABEL).innerText;
        const message = `Dit is geen geldige waarde voor het '${labelText}' veld.`;

        // console.log(
        //     'input = hallo',
        //     this.getRegex(validationType).test(this.field.value), // false
        //     /^[a-zA-Z]+$/.test(this.field.value), // true
        //     this.field.value
        // );

        let hasError =
            this.hasValue(this.field) && !this.getRegex(validationType).test(this.field.value) ? true : false;
        this.error.errorData(hasError, `validation-type`, this.field, this.element, message);
    }

    getRegex(sort) {
        let validation;

        switch (sort) {
            case 'number':
                validation = /^\d+$/;
            case 'name':
                validation = /^[a-zA-Z]+$/;
            case 'address':
                validation = /^[a-zA-Z0-9\s,.'-]{3,}$/;

            case 'phone':
                validation = /^((\s|[\.\-\+\(\)\[\]])*[0-9]){6,14}$/;
                break;
            case 'email':
                validation = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                break;
            default:
                validation = false;
                break;
        }
        return validation;
    }
}

export default ValidationType;
