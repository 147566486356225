import InitializeScript from '~utilities/initialize-script';
import File from './file';
import Length from './length';
import Error from './error';
import Required from './required';
import ValidationType from './validation-type';
import Field from './field';
import CustomSelect from './custom-select';

const JS_FORM = 'js-form';
const JS_LABEL = '[js-label]';
const JS_ITEM = '[js-item]';
const JS_REQUIRED = 'js-required';
const JS_VALIDATION_TYPE = 'js-validation-type';

class Form extends Field{
    constructor(element) {
        super();

        this.element = element;
        this.error = new Error;
        this.focus = false;
        this.options();
        

        InitializeScript.async('js-file', File, this.element);

        InitializeScript.async('js-custom-select', CustomSelect, this.element);
        new Length(this.element, this.error);

        this.bindEvents();
    }

    options() {
        let attributes = this.element.getAttribute(JS_FORM);
        if(!attributes) return;
        attributes = attributes.split('|');
        
        attributes.forEach(attribute => {
            switch(attribute) {
                case 'focus':
                    this.focus = true;
                    break;
                case 'optional':  
                    this.showRequiredLabel(false);
                    break;
                case 'required':   
                    this.showRequiredLabel(true);
                    break;
                default:
                    console.warn(`form.options(): ${attribute} is not an option.`);
                    break;
            }
        });
    }

    showRequiredLabel(required) {
        const items = [...this.element.querySelectorAll(JS_ITEM)];
        items.forEach(item => {
            const label = item.querySelector(JS_LABEL);

            if(item.hasAttribute(JS_REQUIRED)){
                if(required) label.innerHTML += ` <span class="c-required-label">*</span>`;
            } else {
                if(!required) label.innerHTML += ` <span class="c-required-label">(optional)</span>`;
            }
        });
    }

    bindEvents() {
        if(this.focus) this.checkForm(false, this.focus);
        this.element.addEventListener("submit", (event) => this.checkForm(event));
    }

    checkForm(event = false, focus = false) {
        const items = [...this.element.querySelectorAll(JS_ITEM)];
        items.forEach(item => {
            if(focus) {
                const field = this.getFields(item)[0];
                field.addEventListener("blur", () => this.checkFields(item));
            } else {
                this.checkFields(item);
            }
        });
        
        if(event) {
            Object.keys(this.error.data).forEach( (key) => {
                if(Object.keys(this.error.data[key]).length) event.preventDefault();
            });
        }
    }

    checkFields(item) {
        if(item.hasAttribute(JS_REQUIRED)) new Required(this.element, this.error, item);
        if(item.hasAttribute(JS_VALIDATION_TYPE)) new ValidationType(this.element, this.error, item);
    }
    
}

export default Form;
