const templates = {};

templates.example = require('./example/template/index.html');
templates.form = {};
templates.form.file = require('./form/template/file.html');
templates.form.length = require('./form/template/length.html');
templates.form.validation = require('./form/template/validation.html');
templates.form.customSelect = require('./form/template/custom-select.html');
templates.modal = require('./modal/template/index.html');
templates.tab = require('./tab/template/index.html');
templates.accordion = require('./accordion/template/index.html');
templates.tabcordion = require('./tabcordion/template/index.html');
templates.tooltip = require('./tooltip/template/index.html');
templates.image = require('./image/template/index.html');
templates.toast = require('./toast/template/index.html');
templates.filter = require('./filter/template/index.html');

export default templates;