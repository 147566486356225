import CustomElement from '~utilities/custom-element';
// import * as data from './mockup_googlemaps.json';
import LoadAsyncScript from './../utils/LoadAsyncScript';

// see JSON data at mockup_googlemaps.json
class GoogleMaps extends CustomElement {
    constructor() {
        super();

        this.map = null;
        this.infoWindow = null;

        this.markers = [];
        this.content = [];

        const initData = {
            settings: {
                key: 'AIzaSyAZ9beDSpQADbrEhNAnyDMiHjelOwbkFMc', // personal key
                name: 'Alle eqin vestigingen',
                position: { lat: 52.049, lng: 5.15, zoom: 7 },
            },
            departments: {},
        };

        this.data = fetchLocations(initData);

        LoadAsyncScript(
            `https://maps.googleapis.com/maps/api/js?key=${this.data.settings.key}`,
            this.initGoogleMaps.bind(this),
            'gm'
        );

        this.attachListeners2();
    }

    attachListeners2() {
        console.log('uhoh')
        const items = [...document.getElementsByClassName('dept__item')];
        items.map((item) => {
            console.log('--->', item);
            if (item.tagName === 'A') {
                const id = item.attributes['data-deptId'].value;
                // item.addEventListener('click', () => {
                //     console.log('clicked');
                //     document.getElementById('tab-2').click();
                //     let items = [...document.querySelectorAll('[data-deptId]')];

                //     items.map((item) => {
                //         if (item.attributes['data-deptId'].value == id) {
                //             item.click();

                //             document.getElementsByClassName('eqinvestigingen')[0].scrollIntoView(true);
                //         }
                //     });
                // });
            }
        });
    }

    // when googlemaps loaded
    initGoogleMaps(payload) {
        this.map = new google.maps.Map(document.getElementById('googlemaps'), {
            zoom: this.data.settings.position.zoom,
            center: this.data.settings.position,
            styles: [
                {
                    elementType: 'geometry',
                    stylers: [
                        {
                            color: '#f5f5f5',
                        },
                    ],
                },
                {
                    elementType: 'labels.icon',
                    stylers: [
                        {
                            visibility: 'off',
                        },
                    ],
                },
                {
                    elementType: 'labels.text.fill',
                    stylers: [
                        {
                            color: '#616161',
                        },
                    ],
                },
                {
                    elementType: 'labels.text.stroke',
                    stylers: [
                        {
                            color: '#f5f5f5',
                        },
                    ],
                },
                {
                    featureType: 'landscape',
                    stylers: [
                        {
                            color: '#c8d002',
                        },
                    ],
                },
                {
                    featureType: 'poi',
                    elementType: 'geometry',
                    stylers: [
                        {
                            color: '#c8d002',
                        },
                    ],
                },
                {
                    featureType: 'road',
                    elementType: 'geometry',
                    stylers: [
                        {
                            color: '#ffffff',
                        },
                    ],
                },
                {
                    featureType: 'road',
                    elementType: 'geometry.fill',
                    stylers: [
                        {
                            color: '#a6ad01',
                        },
                    ],
                },
                {
                    featureType: 'road.highway',
                    elementType: 'geometry',
                    stylers: [
                        {
                            color: '#a6ae00',
                        },
                    ],
                },
                {
                    featureType: 'transit',
                    elementType: 'geometry.fill',
                    stylers: [
                        {
                            color: '#a6ad01',
                        },
                    ],
                },
                {
                    featureType: 'water',
                    elementType: 'geometry',
                    stylers: [
                        {
                            color: '#7e8700',
                        },
                    ],
                },
                {
                    featureType: 'water',
                    elementType: 'labels.text.fill',
                    stylers: [
                        {
                            color: '#9e9e9e',
                        },
                    ],
                },
            ],
        });

        let infoWindow = new google.maps.InfoWindow();
        let marker;
        let j = 0;

        // bind click to zoom to location
        [...this.getElementsByTagName('a')].map((v) => {
            const id = v.getAttribute('data-deptId');
            const data = this.data.departments.filter((d) => d.id == id)[0];

            // create google maps modal
            marker = new google.maps.Marker({
                position: { lat: parseFloat(data.lat), lng: parseFloat(data.lng) },
                map: this.map,
                content: '',
                data: data,
                icon: '/assets/img/map-marker.png',
            });

            v.addEventListener('click', () => this.zoomToMarker(v));

            let m = google.maps.event.addListener(
                marker,
                'click',
                (function (marker) {
                    return function () {
                        infoWindow.setContent(`<div class="gm-modal">${data.html}</div>`);
                        console.log(data.html);
                        infoWindow.open(this.map, marker);
                    };
                })(marker, j)
            );

            this.markers.push(marker);

            j++;
        });
    }

    zoomToMarker(data) {
        const markerId = data.getAttribute('data-deptId');
        const marker = this.markers.filter((marker) => marker.data.id == markerId);
        google.maps.event.trigger(marker[0], 'click');
    }

    template() {
        let frag = document.createDocumentFragment();

        // the header of the department list list
        let header = document.createElement('h3');
        header.className = 'h1-upper';
        header.innerText = this.data.settings.name;

        // adding list
        let el = document.createElement('div');
        el.className = 'dept__list';
        el.appendChild(header);
        el.appendChild(departmentItems(this.data.departments));
        frag.appendChild(el);

        // // adding googlemaps
        el = document.createElement('div');
        el.className = 'dept__maps';
        el.id = 'googlemaps';

        frag.appendChild(el);
        this.appendChild(frag);
    }

    connectedCallback() {
        // if (showTemplate) this.innerHTML = this.template();
        this.element = this.template();
        this.callback();
        this.removeAttributes();
    }

    callback() {
        super.callback();
    }
}

export default GoogleMaps;

// category component
function departmentItems(data) {
    let frag = document.createDocumentFragment();
    let headQuarter = null;

    // filter the types
    let types = data
        .map((d) => d.type)
        .reduce(function (a, b) {
            if (a.indexOf(b) < 0) a.push(b);
            return a;
        }, []);

    // create items per type
    types.map((type) => {
        const numOfItems = (type.match(/,/g) || []).length;

        const items = data.filter((obj) => obj.type === type);
        let div = document.createElement('div');
        let span = document.createElement('span');
        span.innerText = type;
        div.appendChild(span);
        items.map((item) => div.appendChild(departmentLinks(item)));

        if (numOfItems > 1) {
            // then it's headquarter
            headQuarter = items[0];
        } else {
            frag.appendChild(div);
        }
    });

    if (headQuarter) {
        [...frag.childNodes].map((item) => {
            item.insertBefore(departmentLinks(headQuarter), item.children[1]);
        });
    }

    return frag;
}

// link component
function departmentLinks(data) {
    let frag = document.createDocumentFragment();
    let a = document.createElement('a');
    a.innerText = data.heading;
    a.setAttribute('data-deptId', `${data.id}`);
    frag.appendChild(a);
    return frag;
}

// this is where the html magic happens
// it transforms html nodes to object
function fetchLocations(data) {
    const nodes = document.getElementById('dept_list');
    const displayElements = ['H2', 'ADDRESS', 'CONTACT'];
    let id = 0;

    let cItems = [...nodes.childNodes].filter((e) => e.nodeName == 'C-ITEM');
    data.departments = cItems.map((el) => {
        const { type, heading, lat, lng, zoom } = el.attributes;
        let button = [...el.childNodes].filter((e) => e.nodeName == 'DIV')[0];
        let els = [...button.childNodes].filter((e) => displayElements.indexOf(e.nodeName) > -1);

        let htmlString = '';
        els.map((e) => (htmlString += e.innerHTML));
        id += 1;

        return {
            id: id,
            type: type.value,
            heading: heading.value,
            lat: lat.value,
            lng: lng.value,
            zoom: zoom.value,
            html: htmlString,
        };
    });

    return data;
}
