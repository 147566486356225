const JS_TEMPLATE = 'js-template';

class CustomElement extends HTMLElement {
    constructor() {
        super();
    }

    template() {}

    callback() {
        this.element = this.childNodes[1];
    }

    removeAttributes() {
        [...this.attributes].forEach(attribute => {
            this.removeAttribute(attribute.name);
        });
    }

    connectedCallback() {
        const showTemplate = this.hasAttribute(JS_TEMPLATE);
        if (showTemplate) this.innerHTML = this.template();

        this.callback();
        this.removeAttributes();
    }
}
export default CustomElement;
