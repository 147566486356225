import CustomElement from '~utilities/custom-element';

class Loader extends CustomElement {
    constructor() {
        super();
    }
    
    template() {
        return `
            <div class="c-loader"></div>
        `;
    }

    callback() {
        super.callback();
    }
}
export default Loader;