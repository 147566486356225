import FilterNodes from './FilterNodes';

export default function Accordion() {
    let rowHeight = 50;
    // return false;
    const init = () => {
        let accordions = [...document.getElementsByClassName('accordion')];

        accordions.map((accordion) => {
            accordionHandler(accordion);
        });
    };

    const accordionHandler = (accordion) => {
        let items = FilterNodes([...accordion.childNodes], 'LI');
        let i = 0;

        const maxHeights = recalculateSectionMaxHeight(items);

        items.map((item) => {
            item.numItems = maxHeights[i];
            rowHeight = item.offsetHeight;

            if (i === 0) {
                item.classList.add('active');
                item.style.maxHeight = `${maxHeights[0] * rowHeight + rowHeight}px`;
            }

            item.addEventListener('click', (el) => {
                console.log('click');
                const path = el.path || (el.composedPath && el.composedPath());
                let otherEls = FilterNodes(FilterNodes([...path], 'UL')[0].childNodes, 'LI');

                let activeState = el.target.classList.contains('active');

                otherEls.map((o) => {
                    // console.log(0);
                    o.style.maxHeight = `${rowHeight}px`;
                    o.classList.remove('active');
                });

                console.log(activeState);

                if (activeState) {
                    el.target.classList.remove('active');
                } else {
                    el.target.classList.add('active');
                    el.target.style.maxHeight = `${item.numItems * rowHeight + rowHeight}px`;
                }
            });
            i++;
        });
    };

    // this is needed for smooth animation
    const recalculateSectionMaxHeight = (items) => {
        return items.map((item) => {
            const numItems = FilterNodes([...FilterNodes(item.childNodes, 'UL')][0].childNodes, 'LI');

            return numItems.length;
        });
    };

    init();
}
