
class ScrollToElement {
    constructor(element, offset = 0, behavior = 'smooth') {
	    const fullOffset = element.getBoundingClientRect().top + document.documentElement.scrollTop - offset;
	    const onScroll = () => {
	        const scrollTop = window.scrollTop || window.pageYOffset

	        if (scrollTop === fullOffset) {
	            window.removeEventListener('scroll', onScroll);
	            const focusable = [...element.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])')];
	            if(focusable.length) focusable[0].focus();
	        }
		}
	    
	    window.addEventListener('scroll', onScroll)
	    onScroll();
	    window.scrollTo({
	        top: fullOffset,
	        left: 0,
	        behavior: behavior
	    })
    }
}

export default ScrollToElement;
