const HAS_TOUCH = 'has--touch';
const HAS_MOUSE = `has--mouse`;
const html = document.documentElement;

class DetectTouch {

    get hasTouch() {
        return this.touch;
    }

    constructor() {
        html.classList.add(HAS_MOUSE); // Fix for IE11

        this.touch = window.matchMedia("(pointer: coarse)").matches;

        if(this.touch) {
            html.classList.remove(HAS_MOUSE);
            html.classList.add(HAS_TOUCH);
        }
    }
}
export default new DetectTouch();