// Router
// import '~routes/router';
import 'promise-polyfill/src/polyfill';
import 'core-js/modules/web.dom-collections.iterator';
import 'core-js/modules/web.dom-collections.for-each';
import 'core-js/modules/es.object.assign';
import 'core-js/modules/es.object.keys';
import 'core-js/modules/es.function.name';
import 'core-js/modules/es.symbol';
import 'core-js/modules/es.set';
import 'core-js/modules/es.array.includes';
import 'core-js/modules/es.string.includes';
import 'core-js/modules/es.array.from';
import 'core-js/modules/es.array.map';
import 'core-js/modules/es.weak-map';
import 'core-js/modules/es.set';

// Polyfills
import '~utilities/polyfill/prepend';
import '~utilities/polyfill/closest';
import '~utilities/polyfill/custom-elements';
import '~utilities/polyfill/find';

// POLYFILLS
// Event.composedPath
(function (E, d, w) {
    if (!E.composedPath) {
        E.composedPath = function () {
            if (this.path) {
                return this.path;
            }
            var target = this.target;

            this.path = [];
            while (target.parentNode !== null) {
                this.path.push(target);
                target = target.parentNode;
            }
            this.path.push(d, w);
            return this.path;
        };
    }
})(Event.prototype, document, window);

// Utilities
import '~utilities/get-service-worker';
import '~utilities/keyboard-focus';
import '~utilities/detect-touch';
// import '~utilities/detect-breakpoints';
import AsyncLoad from '~utilities/async-load';
import InitializeScript from '~utilities/initialize-script';

// Development
import Styleguide from '~development/styleguide/js';
import Devbar from '~development/devbar/js';

// CORE Components
import Example from '~components/example/js';
import Form from '~components/form/js';
import BypassNavigation from '~components/bypass-navigation/js';
import Modal from '~components/modal/js';
import Tab from '~components/tab/js';
// import Accordion from '~components/accordion/js';
import Tabcordion from '~components/tabcordion/js';
import Tooltip from '~components/tooltip/js';
import Image from '~components/image/js';
import Toast from '~components/toast/js';
import Filter from '~components/filter/js';
import Loader from '~components/loader/js';

// Components
import Item from 'components/Item';
import Menu from 'components/Menu';
import Search from 'components/Search';
import GoogleMaps from 'components/GoogleMaps';
import Car from 'components/Carousel';
import ToggleClassHelper from './utils/ToggleClassHelper';
import Carousel from './utils/Carousel';
import Accordion from './utils/Accordion';
import ImagePopup from './utils/ImagePopup';
import FormHandler from './utils/FormHandler';
import CountryFilter from './utils/CountryFilter';
onLoad();

// Custom Elements
customElements.define('c-loader', Loader);
customElements.define('c-item', Item);
customElements.define('c-googlemaps', GoogleMaps);
customElements.define('c-menu', Menu);
customElements.define('c-carousel', Car);

customElements.define('c-search', Search);

// add expand class on expandable classes when clicked
ToggleClassHelper('expandable', 'expand', 'click', { calculateHeight: true, initHeight: '350px' });

export function onLoad() {
    // this is wrong. should made components
    Carousel();
    Accordion();
    ImagePopup();
    FormHandler();
    CountryFilter();

    // InitializeScript.async('js-async-load', AsyncLoad);
    // InitializeScript.async('js-styleguide', Styleguide);
    // InitializeScript.async('js-devbar', Devbar);
    InitializeScript.async('js-bypass', BypassNavigation);
    // InitializeScript.async('js-example', Example);
    InitializeScript.async('js-modal', Modal);
    InitializeScript.async('js-form', Form);
    InitializeScript.async('js-tab', Tab);
    InitializeScript.async('js-accordion', Accordion);
    InitializeScript.async('js-tabcordion', Tabcordion);
    InitializeScript.async('js-tooltip', Tooltip);
    InitializeScript.async('js-image', Image);
    // InitializeScript.async('js-toast', Toast);
    // InitializeScript.async('js-filter', Filter);
}
