const FIELDS = 'input, textarea';

class Field {

    getFields(element) {
        return [...element.querySelectorAll(FIELDS)];
    }
    
    hasValue(fields) {
        this.checked = 0;
        if(fields.length){
            fields.forEach(field => {
                this.checkValue(field);
            });
        } else {
            this.checkValue(fields);
        }
        
        if(!this.checked) return false;
        return true;
    }

    checkValue(field) {
        if(field.type === 'checkbox' || field.type === 'radio') {
            if(field.checked) this.checked++;
        }else if(field.value) this.checked++;
        return this.checked;
    }
}

export default Field;