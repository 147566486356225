import TabIndexChildren from '~utilities/tab-index-children';
import ScrollToElement from '~utilities/scroll-to-element';

// TOODO: 
// Add option for not collapsing other panels

const ACTIVE_CLASS = 'is--active';
const ANIMATING_CLASS = 'is--animating';
const ANIMATED_CLASS = 'is--animated';
const JS_ACCORDION = 'js-accordion';
const JS_ACCORDION_OFFSET = 'js-accordion-offset';

class Accordion {
    constructor(element) {
        this.element = element;
        this.accordions = [...element.querySelectorAll('[js-accordion-header]')];
        this.options();
        this.setAttribute();

        this.accordions.forEach(accordion => {
            accordion.addEventListener('click', () => this.changeAccordion(accordion));
            
            const accordionPanel = this.getPanel(accordion);
            accordionPanel.addEventListener('transitionend', (event) => this.animationEnd(event, accordionPanel, accordion));
        });
    }

    options() {
        let attributes = this.element.getAttribute(JS_ACCORDION);
        if(!attributes) return;
        attributes = attributes.split('|');

        attributes.forEach(attribute => {
            switch(attribute) {
                case 'scroll':
                    this.scroll = true;
                    break;
                case 'expand':  
                    this.expand = true;
                    break;
                default:
                    console.warn(`accordion.options(): ${attribute} is not an option.`);
                    break;
            }
        });
    }

    setAttribute() {
        this.accordions.forEach((accordion, index) => {
            const id = accordion.getAttribute('js-accordion-header');
            const accordionPanel = this.getPanel(accordion);
            accordionPanel.setAttribute('role','region');
            accordionPanel.setAttribute('aria-labelledby', `${accordion.getAttribute('js-accordion-header')}`);
            accordionPanel.id = `accordion-panel-${id}`;
            accordion.id = id
            accordion.setAttribute('aria-controls', `accordion-panel-${id}`);
            
            let current = accordion.getAttribute('aria-expanded') === 'true';
            if(current) accordion.classList.add(ANIMATED_CLASS);
            this.setPanel(accordion, accordionPanel, current);
            accordion.classList.remove(ANIMATING_CLASS);
        });
    }

    getPanel(accordion) {
        return this.element.querySelector(`[js-content='${accordion.getAttribute('js-accordion-header')}']`);
    }

    changeAccordion(target) {
        this.accordions.forEach(accordion => {
            
            accordion.classList.remove(ANIMATING_CLASS, ACTIVE_CLASS, ANIMATED_CLASS);
            const accordionPanel = this.getPanel(accordion);
            let current = accordion === target;

            if(target.getAttribute("aria-expanded") === 'true') current = false;

            const toggle = current ? true : false;            
            accordion.setAttribute("aria-expanded", toggle);
            
            this.setPanel(accordion, accordionPanel, current);
        });
    }

    setPanel(accordion, accordionPanel, current) {
        const classList = current ? 'add' : 'remove';
        const height = current ? accordionPanel.scrollHeight : 0;
        const tabIndex = current ? 0 : -1;

        accordionPanel.style.maxHeight = `${height}px`;
        accordion.classList[classList](ANIMATING_CLASS, ACTIVE_CLASS);
        new TabIndexChildren(accordionPanel, tabIndex, true);
    }

    animationEnd(event, accordionPanel, accordion) {
        if(accordion.classList.contains(ACTIVE_CLASS) &&
            event.target === accordionPanel){
            accordion.classList.remove(ANIMATING_CLASS);
            accordion.classList.add(ANIMATED_CLASS);

            const offset = this.element.getAttribute(JS_ACCORDION_OFFSET);
            if(this.scroll) new ScrollToElement(accordion, offset);
        }
    }
    
}

export default Accordion;