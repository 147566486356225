const templates = [
    'async-load', 
    'detect-breakpoints',
    'detect-touch',
    'external-links',
    'fetch',
    'keyboard-focus',
    'load',
    'max-characters',
    'observer',
    'polyfill',
    'scroll-to-element',
    'tab-index-children',
    'toggle-class',
    'sticky',
    'custom-element',
];

export default templates;