import ToggleClass from '~utilities/toggle-class';
import TabIndexChildren from '~utilities/tab-index-children';

const JS_MODAL_OPEN = 'js-modal-open';
const JS_MODAL_CLOSE = 'js-modal-close';
const ACTIVE_CLASS = 'is--active';
const JS_MODAL_BACKGROUND = 'js-modal-background';
const ESCAPE = 27;

class Modal {
    constructor(element) {
        this.element = element;
        this.buttonsOpen = [...document.querySelectorAll(`[${JS_MODAL_OPEN}=${element.id}]`)];
        this.buttonsClose = [...element.querySelectorAll(`[${JS_MODAL_CLOSE}]`)];
        
        this.addModalBackground();
        this.modalBackground = document.querySelector(`[${JS_MODAL_BACKGROUND}]`);
        this.bindEvents();
    }

    bindEvents(){
        if(this.buttonsOpen) {
            this.buttonsOpen.forEach((open) => {
                open.addEventListener("click", () => this.openModal());
            });
        }
        if(this.buttonsClose) {
            this.buttonsClose.forEach((close) => {
                close.addEventListener("click", () => this.closeModal());
            });
        }
        this.modalBackground.addEventListener("click", () => this.closeModal());
        
        document.addEventListener('keyup', event => {
            if (event.keyCode === ESCAPE) this.closeModal();
        });
    }

    toggleModal(state){
        new ToggleClass(state, this.element, ACTIVE_CLASS);
        new ToggleClass(state, document.documentElement, 'has--modal');
    }

    openModal(){
        this.toggleModal(true);
        new TabIndexChildren('body', -1);
        new TabIndexChildren('[js-modal]', 0);
    }

    closeModal(){
        this.toggleModal(false);
        new TabIndexChildren('body', 0);
        new TabIndexChildren('[js-modal]', -1);

        const closeAttribute = this.buttonsClose[0].getAttribute(JS_MODAL_CLOSE);
	    if(closeAttribute === 'remove') this.element.parentNode.removeChild(this.element);
    }

    addModalBackground() {
        const modalBackground = document.querySelector(`[${JS_MODAL_BACKGROUND}]`);
        if (!modalBackground) {
            const element = document.createElement(`div`);
            element.setAttribute(JS_MODAL_BACKGROUND, '');
            element.classList.add(`modal__background`);
            document.documentElement.appendChild(element);
        }
    }
}

export default Modal;