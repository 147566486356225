import CustomElement from '~utilities/custom-element';

class Search extends CustomElement {
	constructor() {
		super()

		this.btnSubmit = this.querySelector('.search__button')
		this.form = this.querySelector('form')
		this.hiddenFields = this.querySelector('.hiddenFields')
		this.inputvalue = this.querySelector('.form__item-input')
		this.option = '';
		this.btnSubmit.addEventListener('click', this.clickHandler.bind(this))
	}

	clickHandler(btn) {
		btn.preventDefault()
		this.option = this.querySelector('input[name="selection"]:checked').value

		if (this.option == 'productcatalogus') {
			this.form.action = `/eqinfo/search/${this.inputvalue.value}`
			this.hiddenFields.remove()
		}

		setTimeout(this.form.submit(), 200)
	}


}


export default Search;