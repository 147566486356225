import ToggleClass from '~utilities/toggle-class';
import Field from './field';

const ERROR_CLASS = 'form__item-length--error';
const ERROR_MESSAGE = 'Waarde is langer dan toegestaan.';
const JS_LENGTH = 'js-length';
let MAX_MESSAGE;

class Length extends Field {
    constructor(form, error) {
        super();
        this.error = error;
        const elements = [...form.querySelectorAll(`[${JS_LENGTH}]`)];
        if(!elements.length) return;

        elements.forEach(element => {
            const field = this.getFields(element.parentElement)[0];

            this.maxLength = element.getAttribute(JS_LENGTH);
            MAX_MESSAGE = `<span>Max. <b>${this.maxLength}</b> tekens</span>`;
            this.showMessage(MAX_MESSAGE, element);

            this.bindEvents(field, element);

        });
    }

    bindEvents(field, element) {
        field.addEventListener("keyup", () => this.showLength(field, element));
    }

    showError(field, element) {
        const length = field.value.length;
        let hasError = length > this.maxLength ? true : false;
        this.error.errorData(hasError, `max-length`, field, element.parentElement, ERROR_MESSAGE);
    }

    showMessage(message, element) {
        element.innerHTML = message;
    }

    showLength(field, element) {
        const length = field.value.length;
        new ToggleClass(length > this.maxLength, element, ERROR_CLASS);

        !length ? this.showMessage(MAX_MESSAGE, element) : this.showMessage(`<span><b>${length}</b>/${this.maxLength} tekens</span>`, element);
        this.showError(field, element)
    }
}

export default Length;
