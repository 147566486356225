export default function CountryFilter() {
    const deptOverview = document.getElementsByClassName('dept__overview')[0];

    const init = () => {
        if (deptOverview) {
            try {
                attachListeners();
            } catch (e) {
                console.log(e);
                return false;
            }
        } else {
            console.log('no dept element found');
            return false;
        }

        document.getElementById('allDepts').classList.add('active');
    };

    const attachListeners = () => {
        const filterButtons = deptOverview.getElementsByClassName('country__filter')[0];
        let j = 0;
        if (filterButtons) {
            [...filterButtons.children].map((btn) => {
                btn.addEventListener('click', () => {
                    applyFilter(document.getElementById('dept_list'), btn.id);
                });
            });
        } else {
            throw Error('no filterbuttons found');
        }
    };

    const applyFilter = (container, id) => {
        const options = { allDepts: '', nlDepts: 'NL', beDepts: 'BE' };

        console.log(id);
        document.getElementById('allDepts').classList.remove('active');
        document.getElementById('nlDepts').classList.remove('active');
        document.getElementById('beDepts').classList.remove('active');
        document.getElementById(id).classList.add('active');
        FilterList(container, options[id]);
    };

    init();
}

export function FilterList(container, id) {
    [...container.children].map((cItem) => {
        if (id) {
            if (cItem.attributes.landcode.value !== id) {
                cItem.classList.add('hide');
            } else {
                cItem.classList.remove('hide');
            }
        } else {
            cItem.classList.remove('hide');
        }
    });
}
