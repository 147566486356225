import 'intersection-observer';

class Observer {
    constructor(element, script) {
        let rootMargin = element.getAttribute('js-observer-root-margin');
	    let threshold = element.getAttribute('js-observer-threshold');
	    
	    if(!rootMargin) rootMargin = '0px 0px 0px 0px';
	    if(!threshold) threshold = '0';

        const config = {
            rootMargin: rootMargin,
            threshold: parseInt(threshold),
        };

        let observer = new IntersectionObserver((entries, self) => {
            entries.forEach(entry => {
                if(entry.isIntersecting) {
                    script.observerTrigger(entry.target);
                    self.unobserve(entry.target);
                }
            });
        }, config);

        observer.observe(element);
    }
}

export default Observer;