import CustomElement from '~utilities/custom-element';

// see JSON data at mockup_googlemaps.json
export default class Carousel extends CustomElement {
    constructor() {
        super();
        if (this.querySelectorAll('.carousel')[0].children.length <= 1) {
            this.disableControls();
        }
    }

    disableControls() {
        this.querySelectorAll('.horizontal')[0].style.display = 'none';
        this.querySelectorAll('.previous')[0].style.display = 'none';
        this.querySelectorAll('.next')[0].style.display = 'none';
    }

    connectedCallback() {}
}
