import CustomElement from '~utilities/custom-element';

class Item extends CustomElement {
	constructor() {
		super();
		this.coords = '';
	}

	template() {
		const {
			heading,
			body,
			img,
			imgName,
			to,
			href,
			date,
			price,
			type,
			icon,
			address,
			links,
			contact,
			filetype,
			lat,
			lng,
			googlemarker_id,
			paragraph,
			name,
			vanaf,
		} = this.attributes;
		if (lat && lng) this.coords = `${lat},${lng}`;


		return `
        ${href
				? `<a class="c-item" href="${href.value}">`
				: `<div class="c-item c-item-button" ${lat ? `coords:"${lat.value},${lng.value}"` : ''
				}>`
			}      
        
            ${img
				? `<div class="c-item__img-wrapper">
                        ${googlemarker_id
					? `<a class="dept__item" data-deptId="${googlemarker_id.value}">`
					: ``
				}
                    <img 
                    class="c-item--img"
                    src="${img.value}" 
                    name="${img.imgName || img.value}"
                    data-src="${img.value}" 
                    js-image 
                    js-observer-threshold="0" 
                    js-observer-root-margin="0px 0px 0px 0px" />
                    ${googlemarker_id ? `</a>` : ``}
                    
                    </div>`
				: ''
			}

            ${icon
				? `<i class='btn ${icon.value} button__nomargin icon'></i>`
				: ''
			}


						${paragraph ? `<h5 class="item__paragraph">${paragraph.value} </h5>` : ''}

                ${heading
				? `<h4 class="item__heading">${heading.value} </h4>`
				: ''
			}

                ${address ? `${c_address(address.value)}` : ''}
                ${links ? `${c_links(links.value)}` : ''}
                ${contact ? `</a>${c_contact(contact.value)}` : ''}

                

                ${body ? `<h4 class="item__body">${body.value}</h4>` : ''}
               
                ${to
				? `<div class="item__to"><span class="flexCenter item__to--anchor"><i class='btn icon-chevron-right'></i></span></div>`
				: ''
			}
                ${filetype
				? `<p class="item__filetype">${filetype.value}</p>`
				: ''
			}
                ${date
				? `<p class="item__date flexCenter">${date.value}</p>`
				: ''
			}
                ${price
				? `<p class="item__price"><span class="price__from">${vanaf ? vanaf.value : ""}</span> &euro;${price.value},- <br><span class="price__vat">excl. BTW</span></p>`
				: ''
			}
                ${this.innerHTML}
           
            ${href ? '</a>' : ' </div>'} 
        `;
	}

	removeAttributes() {
		if (!this.coords)
			[...this.attributes].map((attribute) => {
				// this.removeAttribute(attribute.name);
			});
	}
}
export default Item;

// dear god, I miss JSX
function c_address(addr) {
	return `<div class='item__dept--address'>
        ${JSON.parse(addr)
			.address.map((v) => {
				return `<p>${v}</p>`;
			})
			.join('')}
    </div>`;
}

function c_links(links) {
	return `<div class='item__dept--links'>
    ${JSON.parse(links)
			.links.map((v) => {
				return `<p class='item__dept--link' href=${v.url}>${v.name}</p>`;
			})
			.join('')}
    </div>`;
}

function c_contact(contact) {
	return `<div class='item__dept--contact'>
    ${JSON.parse(contact)
			.contact.map((v) => {
				return `<p><i class='icon icon-dark icon-medium icon-${validateEmail(v) ? 'email' : 'phone'
					}'></i><a href="${validateEmail(v) ? `mailto` : `tel`
					}:${v}">${v}</a></p>`;
			})
			.join('')}
</div>`;
}

function validateEmail(email) {
	var re =
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}
