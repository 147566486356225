const KEYBOARD_FOCUSED = 'has--keyboard-focus';

class KeyboardFocus {

    constructor() {
        this.keyDown = false;

        document.addEventListener('keydown', () => this.handleKey(true), true);
        document.addEventListener('keyup', () => this.handleKey(false), true);
        document.addEventListener('mouseleave', () => this.handleKey(false));
        document.addEventListener('focus', () => this.handleFocus(), true);
        document.addEventListener('blur', () => this.handleBlur(), true);
        document.addEventListener('mouseenter', () => {
            this.handleBlur();
            this.handleKey(false);
        }, true);
    }

    handleKey(pressed) {
        this.keyDown = pressed;
    }

    handleFocus() {
        if(this.keyDown) document.documentElement.classList.add(KEYBOARD_FOCUSED);
    }

    handleBlur() {
        document.documentElement.classList.remove(KEYBOARD_FOCUSED);
    }

}

export default new KeyboardFocus();