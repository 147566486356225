const IS_ACTIVE = 'is--active';
const JS_DEVBAR = 'js-devbar';

class Devbar {
    constructor(element) {
        this.element = element;

        this.toggleGridButton = this.element.querySelector('[js-devbar-toggle-grid]');
        this.screenSize = this.element.querySelector('[js-devbar-size]');
        this.mediaQuery = this.element.querySelector('[js-devbar-media]');
        this.gridContainer = document.querySelector('[js-grid-container]');
        this.options();
        this.getScreenSize();
        this.bindEvents();
    }

    options() {
        let attributes = this.element.getAttribute(JS_DEVBAR);
        attributes = attributes.split('|');

        attributes.forEach(attribute => {
            switch(attribute) {
                case 'fluid':
                    this.gridContainer.classList.add('container-fluid');
                    break;
                default:
                    this.gridContainer.classList.add('container');
                    break;
            }
        });
    }

    bindEvents() {

        this.toggleGridButton.addEventListener('click', () => this.toggleGrid());
        window.addEventListener('resize', () => this.getScreenSize());
    }

    toggleGrid() {
        const grid = document.querySelector('.d-grid-view');

        grid.classList.contains(IS_ACTIVE) ? grid.classList.remove(IS_ACTIVE) : grid.classList.add(IS_ACTIVE);
    }

    getScreenSize() {
        const {innerWidth, innerHeight} = window;
        this.screenSize.innerHTML = `${innerWidth}x${innerHeight}`;

        this.getMediaQuery(innerWidth);
    }

    getMediaQuery() {
        this.mediaQuery.innerHTML = this.findBootstrapEnvironment();
    }

    findBootstrapEnvironment() {
        let envs = ['xs', 'sm', 'md', 'lg', 'xl'];
    
        let element = document.createElement('div');
        this.element.appendChild(element);
    
        let curEnv = envs.shift();
    
        for (let env of envs.reverse()) {
            element.classList.add(`d-${env}-none`);
    
            if (window.getComputedStyle(element).display === 'none') {
                curEnv = env;
                break;
            }
        }
    
        this.element.removeChild(element);
        return curEnv;
    }
}

export default Devbar;
