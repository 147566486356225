const SR_ONLY_CLASS = 'u-sr-only';

class BypassNavigation {
    constructor(element) {
        const accessibilityLinks = [...element.querySelectorAll('a')];
        
        accessibilityLinks.forEach((link) => {
            link.addEventListener('focus', function() {
                element.classList.remove(SR_ONLY_CLASS);
            });
            link.addEventListener('blur', function() {
                element.classList.add(SR_ONLY_CLASS);
            });
        });
    }
}

export default BypassNavigation;