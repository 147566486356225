/**
 * toggle class at all class elements
 * edb - 0t9
 *
 * @param {string} className
 * @param {string} addClassName
 * @param {string} event
 * @return {array} The dom array with the attached listeners
 *
 * @example
 * ToggleClassHelper('expandable', 'expand', 'click');
 *
 * @todo
 * refactor to TS
 * error handling
 */

import FilterNodes from './FilterNodes';

export default function toggleClassHelper(className, addClassName, event, options) {
    let calculateHeight = 0;
    let initHeight = '140px';
    if (options) {
        calculateHeight = options.calculateHeight;
        initHeight = options.initHeight || initHeight;
    }

    return [...document.getElementsByClassName(className)].map(el => {
        el.addEventListener(event, e => {
            if (calculateHeight) {
                if (el.classList.contains('expand')) {
                    el.style.maxHeight = initHeight;
                } else {
                    const heights = FilterNodes(el.children, 'DIV').map(el => {
                        return parseInt(window.getComputedStyle(el).getPropertyValue('height'));
                    });

                    el.style.maxHeight = `${heights.sort()[0] + 150}px`;
                }
            }

            el.classList.toggle(addClassName);
        });
    });
}
