import '~utilities/polyfill/prepend';
import Tab from '~components/tab/js';
import Accordion from '~components/accordion/js';

const JS_TABCORDION = 'js-tabcordion';
const JS_TABCORDION_HEADER = 'js-tabcordion-header';
const JS_ACCORDION_HEADER = 'js-accordion-header';
const JS_TABCORDION_LABEL = 'js-tabcordion-label';
const JS_TABCORDION_TOGGLE = 'js-tabcordion-toggle';
const IS_ACTIVE = 'is--active';
const JS_CONTENT = 'js-content';
const JS_TAB_HEADER = 'js-tab-header';
const DISABLED = '--disabled';

class Tabcordion {
    constructor(element) {
        this.element = element;
        
		this.headings = [...this.element.querySelectorAll(`[${JS_TABCORDION_HEADER}]`)];
		this.addTabOrAccordionClass();
		
		if(!this.element.hasAttribute('js-tab')){
	        this.element.setAttribute('js-accordion', this.element.getAttribute(JS_TABCORDION));
	        this.element.setAttribute('js-tab', '');
	        this.createComponents();
        }
        
        this.showTabOrAccordion();

        window.addEventListener('resize', () => {
            this.addTabOrAccordionClass();
            this.showTabOrAccordion();
        });
    }

    createComponents() {
        this.tablist = document.createElement('div');
        this.tablist.classList.add('tab__list');
        this.tablist.setAttribute('aria-label', this.element.getAttribute(JS_TABCORDION_LABEL));
        this.tablist.setAttribute('js-tab-list', '');

        this.element.prepend(this.tablist);

        this.headings.forEach((heading, index) => {
            const toggle = index ? false : true;
            const id = heading.getAttribute(JS_TABCORDION_HEADER);
            const content = this.element.querySelector(`[${JS_CONTENT}='${id}']`);

            this.createAccordion(heading, toggle, id, content);
            this.createTab(heading, toggle, id, content);
        });

        new Accordion(this.element);
        new Tab(this.element);
    }

    showTabOrAccordion() {
        const contents = [...this.element.querySelectorAll(`[${JS_CONTENT}]`)];
        const tabcordionHeaders = [...this.element.querySelectorAll(`[${JS_TABCORDION_HEADER}]`)];

        tabcordionHeaders.forEach((header) => {
            const id = header.getAttribute('js-tabcordion-header');
            const tab = this.element.querySelector(`[js-tab-header='${id}']`);
            const panel = this.element.querySelector(`[js-content='${id}']`);
            const panelId = this.isTab ? `tab-panel-${id}` : `accordion-panel-${id}` ;
            panel.id = panelId;

            tab.id = tab.id.replace(DISABLED,'');
            header.id = header.id.replace(DISABLED,'');
            if(this.isTab) {
                header.id = `${header.id}${DISABLED}`;
            } else{
                tab.id = `${tab.id}${DISABLED}`;
            }
        });

        contents.forEach((content) => {
            if(this.isTab){
                const activeTab = this.element.querySelector('.tab__tab.is--active');
                const activeTabContentId = activeTab.getAttribute('aria-controls');
                
                content.style.maxHeight = '100%';
                if(activeTabContentId === content.id) content.classList.add(IS_ACTIVE);
            }else {
                const activeAccordion = this.element.querySelector('.accordion__header.is--active');
                const activeAccordionContentId = activeAccordion.getAttribute('aria-controls');

                content.classList.remove(IS_ACTIVE);
                const contentHeight = activeAccordionContentId === content.id ? `${content.scrollHeight}px` : '0';
                content.style.maxHeight = contentHeight;
            }
        });
    }

    addTabOrAccordionClass(){
        const switchWidth = this.element.getAttribute(JS_TABCORDION_TOGGLE);
        this.isTab = window.innerWidth > switchWidth;
        const addTab = this.isTab ? 'add' : 'remove';
        const addAccordion = this.isTab ? 'remove' : 'add';

        this.element.classList[addTab]('is--tab');
        this.element.classList[addAccordion]('is--accordion');
    }

    createTab(heading, toggle, id, content) {
        const element = document.createElement('button');
        element.setAttribute('role', 'tab');
        element.setAttribute(JS_TAB_HEADER, id);
        element.setAttribute('aria-selected', toggle);
        element.id = `tabof-${id}`; //TODO: Fix: this id is overriden
        element.innerHTML = heading.innerHTML;

        content.classList.add('tab__panel');

        this.tablist.appendChild(element);
    }

    createAccordion(heading, toggle, id, content) {
        heading.setAttribute(JS_ACCORDION_HEADER, `${heading.getAttribute(JS_TABCORDION_HEADER)}`);
        heading.classList.add('accordion__header');
        heading.setAttribute('aria-expanded', toggle);
        heading.id = `accordionof-${id}`; //TODO: Fix: this id is overriden
        
        content.classList.add('accordion__panel');
    }
    
}

export default Tabcordion;