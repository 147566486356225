export default function FormHandler() {
    // expands the form
    const contactForm = document.getElementsByClassName('toggle-contact-form');
    if (contactForm.length > 0) {
        contactForm[0].addEventListener('click', (el) => {
            contactForm[0].classList.toggle('expand');
            document.getElementsByClassName('form--container')[0].classList.toggle('expand');
        });
    }

    // expands the  naw

    if (document.getElementsByClassName('c-form__additionalInfo').length > 0) {
        document.getElementsByClassName('c-form__additionalInfo')[0].addEventListener('click', (el) => {
            document.getElementsByClassName('c-form__place')[0].classList.toggle('expand');
            document.getElementsByClassName('c-form__additionalInfo')[0].classList.toggle('active');
        });
    }
}
