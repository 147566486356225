const JS_TOAST = 'js-toast';
const JS_TOAST_MESSAGE = 'js-toast-message';
const TOAST_CLASS = 'c-toast';

class Toast {
    constructor(element) {
        this.element = element;

        this.element.addEventListener('click', () => {
            this.showToast();
        });
    }
    
    showToast() {
        const message = this.element.getAttribute(JS_TOAST_MESSAGE);
        const toast = document.createElement('div');
        const duration = this.element.getAttribute(JS_TOAST);
        
        toast.classList.add(TOAST_CLASS);
        toast.innerHTML = message;
        toast.classList.add(`toast--${duration}`);
        document.body.appendChild(toast);

        toast.addEventListener("animationend", () => {
            toast.remove();
        });
    }

}

export default Toast;