const JS_TOOLTIP_POSITION = `js-tooltip-position`;
const JS_TOOLTIP_NEW_POSITION = `js-tooltip-new-position`;
const TOOLTIP_POSITIONS = ['tooltip--right', 'tooltip--left', 'tooltip--top', 'tooltip--bottom'];
const JS_TOOLTIP = `js-tooltip`;
const html = document.documentElement;

class Tooltip {
    constructor(element, attribute) {
        this.element = element;
        this.attribute = attribute;

        this.tool = this.element.querySelector(`[js-tooltip-tool]`);
        this.tip = this.element.querySelector(`[js-tooltip-tip]`);
        this.arrow = this.element.querySelector(`[js-tooltip-arrow]`);
        this.position = this.element.getAttribute(JS_TOOLTIP_POSITION);
        this.positionClass = `tooltip--${this.position}`;

        this.options();
        this.bindEvents();
        
        this.setPositionClass(this.positionClass);
    }

    options() {
        let attributes = this.element.getAttribute(this.attribute);
        if(!attributes) return;
        attributes = attributes.split('|');
        attributes.forEach(attribute => {
            switch(attribute) {
                case 'click': 
                    this.element.classList.add('tooltip--click');
                    this.click = true;
                    break;
                default:
                    console.warn(`accordion.options(): ${attribute} is not an option.`);
                    break;
            }
        });
    }

    setPositionClass(positionClass) {
        this.element.classList.add(positionClass);

        const newPosition = positionClass.replace('tooltip--', '');
        this.element.setAttribute(JS_TOOLTIP_NEW_POSITION, newPosition);
    }

    bindEvents() {

        this.element.addEventListener('focusin', () => {
            if(html.classList.contains('has--keyboard-focus') || this.click) {
                this.element.classList.add('is--active');
                this.showTip(this.position);
            }
        });
        this.element.addEventListener('focusout', (event) => {
	        const leavingParent = !this.element.contains(event.relatedTarget);
	        if (leavingParent) {
	            this.element.classList.remove('is--active');
	            this.setTip(this.positionClass, true);
            }
        });

        if(!this.click) {
            this.element.addEventListener('mouseenter', () => this.showTip(this.position));
            this.element.addEventListener('mouseleave', () => this.setTip(this.positionClass, true));
        }
    }

    showTip(position){
        const {y,x} = this.tip.getBoundingClientRect();
        const {clientWidth, clientHeight} = this.tip;
        const {innerHeight, innerWidth} = window;
        const screenOffset = {}
        screenOffset.top = y;
        screenOffset.right = innerWidth - (x + clientWidth);
        screenOffset.bottom = innerHeight - (y + clientHeight);
        screenOffset.left = x;

        switch(position) {
            case 'left':
            case 'right':
                if(screenOffset.left < 0 || screenOffset.right < 0 ) this.differentPositionTip();
                else if(screenOffset.top < 0 || screenOffset.bottom < 0 ) this.repositionTip(screenOffset);
                break;
            case 'top':
            case 'bottom':
                if(screenOffset.top < 0 || screenOffset.bottom < 0) this.differentPositionTip();
                else if(screenOffset.left < 0 || screenOffset.right < 0) this.repositionTip(screenOffset);
                break;
        }
    }

    setTip(positionClass, reset = false){
        this.element.classList.remove(...TOOLTIP_POSITIONS);
        this.setPositionClass(positionClass);

        if(reset) {
	        this.tip.removeAttribute("style");
	        this.arrow.removeAttribute("style");
	    }
    }


    getIndexCurrentPosition() {
        const newPosition = this.element.getAttribute(JS_TOOLTIP_NEW_POSITION);;
        const newPositionClass = `tooltip--${newPosition}`;
        return TOOLTIP_POSITIONS.indexOf(newPositionClass);
    }

    differentPositionTip(){
        const index = this.getIndexCurrentPosition();
        let newIndex = index + 1;
        if(newIndex >= TOOLTIP_POSITIONS.length) newIndex = 0;

        this.setTip(TOOLTIP_POSITIONS[newIndex]);
        this.showTip(this.element.getAttribute(JS_TOOLTIP_NEW_POSITION));
    }

    capitalize(string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
    }

    repositionTip(screenOffset){
        const outOf = Object.keys(screenOffset).filter(key => screenOffset[key] < 0);
        outOf.forEach(out => {
            let tipOffset, arrowOffset, marginName;
            const margin = screenOffset[out];
            const negativeMargin = Math.abs(margin);

            switch(out){
                case 'bottom':
                    tipOffset = margin;
                    arrowOffset = negativeMargin;
                    marginName = `marginTop`;
                break;
                case 'right':
                    tipOffset = margin;
                    arrowOffset = negativeMargin;
                    marginName = `marginLeft`;
                break;
                default:
                    tipOffset = negativeMargin;
                    arrowOffset = margin;
                    marginName = `margin${this.capitalize(out)}`;
                break;
            }

            this.tip.style[marginName] = `${tipOffset}px`;
            this.arrow.style[marginName] = `${arrowOffset}px`;
        });
    }
}

export default Tooltip;